import { useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import Login from '../pages/authentication/Login'
import {
	selectIsAuthCheckLoading,
	selectUser,
	selectIsAuthCheckUsingTokenLoading
} from '../selectors/auth'
import LoadingSpinner from './LoadingSpinner'

const AuthGuard = (props) => {
	const { children } = props
	const location = useLocation()
	const [requestedLocation, setRequestedLocation] = useState<string | null>(null)
	const isAuthCheckLoading = useSelector(selectIsAuthCheckLoading)
	const isAuthCheckUsingTokenLoading = useSelector(selectIsAuthCheckUsingTokenLoading)
	const user = useSelector(selectUser)

	if (isAuthCheckLoading || isAuthCheckUsingTokenLoading) {
		return <LoadingSpinner />
	}

	if (!user) {
		if (location.pathname !== requestedLocation) {
			setRequestedLocation(location.pathname)
		}

		return <Login />
	}

	// This is done so that in case the route changes by any chance through other
	// means between the moment of request and the render we navigate to the initially
	// requested route.
	if (requestedLocation && location.pathname !== requestedLocation) {
		setRequestedLocation(null)
		return <Navigate to={requestedLocation} />
	}

	return children
}

AuthGuard.propTypes = {
	children: PropTypes.node
}

export default AuthGuard
