import CustomizedLabels from './CustomizedLabels'

export const shouldHideComingSoon = (currentConsolidator: IConsolidator | undefined) =>
	currentConsolidator?.settings?.hideComingSoon ?? false

export const shouldHideAccountsReceivable = (currentConsolidator: IConsolidator | undefined) =>
	currentConsolidator?.settings?.hideAccountsReceivable ?? false

export const shouldHideInviteUserWithEmailButton = (consolidator?: IConsolidator) =>
	consolidator?.settings?.hideInviteUserWithEmail === true

// DASH-1624: Only display the Payouts by Card Type for MVP practices (for now)
export const shouldShowTotalsByCardType = (currentConsolidator: IConsolidator | undefined) => {
	if (currentConsolidator?.settings?.shouldShowTotalsByCardType) {
		return true
	}
	return false
}

export const shouldShowManualPaymentTabToggle = (currentConsolidator: IConsolidator | undefined) =>
	currentConsolidator?.settings?.allowDisablingPhonePayments ?? false

export const shouldShowOpenActionItems = (currentConsolidator: IConsolidator | undefined) =>
	currentConsolidator?.settings?.showOpenActionItems ?? false

export const shouldRequireInvoiceNumber = (currentConsolidator: IConsolidator | undefined) =>
	currentConsolidator?.settings?.requireInvoiceNumber ?? false

export const getSettingByName = (consolidator: IConsolidator | undefined, settingName: string) =>
	consolidator?.settings?.[settingName] ?? false

// We have some settings that may or may not have the "page".  Remove this when we are all on unified.
export const getSettingByNameAddPage = (
	consolidator: IConsolidator | undefined,
	settingName: string
) =>
	consolidator?.settings?.[settingName] ?? consolidator?.settings?.[`${settingName}Page`] ?? false

/**
 * Checks whether a specific feature is enabled based on the provided setting name and consolidator object.
 * Returns false ONLY if the settingName is explicitly defined in the consolidator settings object with a value of false.
 * Returns true if the settingName is not defined in the consolidator settings object or if its value is truthy.
 */
export const isFeatureEnabled = (consolidator, settingName) =>
	(consolidator?.settings?.[settingName] ?? true) && consolidator?.settings?.[settingName] !== false

export const getAdminSectionProps = (
	consolidator: IConsolidator | undefined,
	openActionItemsCount: number
): AdminSectionProps => ({
	showCheckReportingPage: getSettingByNameAddPage(consolidator, 'showCheckReporting'),
	showAllInvoicesReportingPage: getSettingByNameAddPage(consolidator, 'showAllInvoices'),
	showAllDisputesPage: getSettingByNameAddPage(consolidator, 'showAllDisputes'),
	showAccountsReceivablePage: getSettingByNameAddPage(consolidator, 'showAccountsReceivable'),
	showVendorNamesPage: getSettingByNameAddPage(consolidator, 'showVendorNames'),
	showOpenActionItemsPage: getSettingByNameAddPage(consolidator, 'showOpenActionItems'),
	openActionItemsCount,
	showHelpDeskPage: getSettingByNameAddPage(consolidator, 'showHelpDesk'),
	showPostcardPage: getSettingByNameAddPage(consolidator, 'showPostcard'),
	showLockboxChecksPage: getSettingByNameAddPage(consolidator, 'showLockboxChecks'),
	showDailyTransactionFeed: getSettingByName(consolidator, 'showDailyTransactionFeed'),
	showConsolidatorPaymentHistory: getSettingByName(consolidator, 'showConsolidatorPaymentHistory'),
	showConsolidatorMonthlyReport: getSettingByName(consolidator, 'showConsolidatorMonthlyReport'),
	showCustomPayoutsV2: shouldShowCustomPayoutsV2(consolidator)
})

export const shouldAllowSavePaymentMethod = (consolidator: IConsolidator | undefined) =>
	getSettingByName(consolidator, 'isSavePaymentMethodsEnabled') === true

export const shouldShowCheckPayments = (consolidator: IConsolidator | undefined) =>
	getSettingByName(consolidator, 'isCheckEnabled') === true

export const shouldShowConsolidatorMonthlyReports = (consolidator: IConsolidator | undefined) =>
	getSettingByName(consolidator, 'showConsolidatorMonthlyReport') === true

export const shouldHideFeeCheckReceipts = (currentConsolidator: IConsolidator | undefined) =>
	currentConsolidator?.settings?.hideFeeCheckReceipts ?? false

export const getValidateClientIdSettings = (
	consolidator: IConsolidator | undefined
): ValidateClientIdSettings | undefined => {
	const validateClientIdSettings = consolidator?.settings?.payment?.validateClientIdSettings
	if (validateClientIdSettings?.enabled) {
		return validateClientIdSettings
	}
	return undefined
}

export const shouldShowLockBoxMVPAdditions = (consolidator: IConsolidator | undefined) =>
	getSettingByName(consolidator, 'lockboxMVPadditions')

export const shouldValidateClientId = (consolidator: IConsolidator | undefined): boolean =>
	Boolean(consolidator?.settings?.payment?.validateClientIdSettings?.enabled)

export const validateInvoiceIsNumber = (consolidator: IConsolidator | undefined) =>
	getSettingByName(consolidator, 'validateInvoiceIsNumber')

export const shouldShowCustomPayoutsV2 = (consolidator: IConsolidator | undefined) => {
	if (!consolidator) return false
	return consolidator.settings?.['useCustomPayoutsV2'] === true
}

export const shouldShowDisputesEmbeddedComponent = (consolidator: IConsolidator | undefined) =>
	consolidator?.settings?.showDisputesEmbeddedComponent

export const shouldShowNewLockboxAddress = (consolidator: IConsolidator | undefined) =>
	getSettingByName(consolidator, 'showNewLockboxAddress')

export const shouldShowPaymentHistoryV2 = (consolidator: IConsolidator | undefined) =>
	getSettingByName(consolidator, 'usePaymentHistoryV2')

export const shouldShowPimsFields = (consolidator: IConsolidator | undefined) =>
	getSettingByName(consolidator, 'showPaymentHistoryPimsFields')

export const shouldShowRefundRequest = (consolidator: IConsolidator | undefined) =>
	getSettingByName(consolidator, 'refundRequest')?.enabled ?? false

export const shouldShowRegionalManager = (consolidator: IConsolidator | undefined) =>
	getSettingByName(consolidator, 'showRegionalManager')

export const shouldUseSupportedCountries = (consolidator: IConsolidator | undefined) =>
	getSettingByName(consolidator, 'supportedCountries')

export const isPaymentRequestEnabled = (consolidator: IConsolidator | undefined) =>
	isFeatureEnabled(consolidator, 'isPaymentRequestEnabled')

export const getCustomPhonePaymentsLabel = () =>
	CustomizedLabels.getCustomLabel('phonePayments.label', 'Phone Payment')

export const shouldHideReconciliation = (consolidator: IConsolidator | undefined) =>
	getSettingByName(consolidator, 'hideReconciliation')

export const shouldHidePayoutsPage = (consolidator: IConsolidator | undefined) =>
	getSettingByName(consolidator, 'hidePayouts')

export const shouldShowMonthlyStatementsPage = (consolidator: IConsolidator | undefined) =>
	getSettingByName(consolidator, 'showMonthlyStatements')
