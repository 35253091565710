import * as Yup from 'yup'
import toast from 'react-hot-toast'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import numeral from 'numeral'
import { FormikErrors, FormikState, FormikTouched } from 'formik'
import { AxiosError } from 'axios'
import { RefundCharge } from 'types/stripe'
import Big from 'big.js'

import { logout } from '../lib/firebase'
import { constructAuthHeader } from './api-helpers'
import { ENV } from '../config'
import { CA_STATES, COUNTRY_CA, ENV_PRODUCTION, US_STATES } from '../constants'
import { isSurchargePayment } from '../services/charge'
import { PaymentHistory } from '../gql/generated-types/custom-graphql'

const baseUrl = `${process.env.REACT_APP_CHECKOUT_WEB_BASE_URL}/api/`
// Create an Error with custom message and code
export function CustomError(code, message) {
	const error = new Error(message) as any
	error.code = code
	return error
}

// eslint-disable-next-line default-param-last
export async function apiRequest(path, method = 'GET', data) {
	const authHeader = await constructAuthHeader()

	const url = baseUrl + path
	return fetch(url, {
		method,
		headers: {
			'Content-Type': 'application/json',
			Authorization: authHeader
		},
		body: data ? JSON.stringify(data) : undefined
	})
		.then((response) => response.json())
		.then((response) => {
			if (response.status === 'error') {
				// Automatically signout user if accessToken is no longer valid
				if (response.code === 'auth/invalid-user-token') {
					logout()
				}

				throw CustomError(response.code, response.message)
			} else {
				return response.data
			}
		})
}

export const getBaseURL = () => window.location.origin

const getCheckoutPaymentServiceError = (error: AxiosError<CheckoutPaymentServiceErrorData>) => {
	const errorData: CheckoutPaymentServiceErrorData | undefined = error?.response?.data
	if (errorData) {
		if (errorData.validation_errors) {
			let errorMessage = 'Failed with Validation Errors: '
			for (const validationError of errorData.validation_errors) {
				errorMessage += `[${validationError.param}] with (${validationError.value}) is ${validationError.msg}`
			}
			return errorMessage
		}
		return `Failed with Error ${errorData.error_message}`
	}
}

const getCheckoutWebBackendError = (error: AxiosError<CheckoutWebErrorData>) => {
	const errorData: CheckoutWebErrorData | undefined = error?.response?.data
	if (errorData) {
		if (errorData.code && errorData.message)
			return `Failed with Error ${errorData.code}: ${errorData.message}`
		if (errorData.message) return `Failed with Error ${errorData.message}`
	}
}
export const getAxiosErrorMessage = <T>(error: AxiosError<T>) =>
	getCheckoutWebBackendError(error as AxiosError<CheckoutWebErrorData>) ||
	getCheckoutPaymentServiceError(error as AxiosError<CheckoutPaymentServiceErrorData>) ||
	'Something went wrong, Please try again later'

export const getYupErrorMessage = (error) => {
	if (error?.errors?.length > 1) return `Failed with Validation Errors ${error.errors.join(',')}`
	if (error?.message) {
		return `Failed with Validation Error ${error.message}`
	}
	return 'Validation Failed, Please make sure all required fields are filled correctly'
}

export const isYupError = (error) => error instanceof Yup.ValidationError
export function successToast(message, options?) {
	toast.success(message, { position: 'top-center', ...options })
}

export function errorToast(message, options?) {
	toast.error(message, { position: 'top-center', ...options })
}

/**
 * Convert given 24 hour Time (not date!) to 12 hour format
 * Expects a valid hh:mm time
 * @param {string} time in String(hh:mm) 24 hours format
 * @returns {string} hh:mm in 12 hours format with AM/PM
 */
export function hhmmTo12Hrs(time = '', padHours = false) {
	if (!time) return ''
	// Check if time is already in 12 hour format
	if (time.toUpperCase().includes('AM') || time.toUpperCase().includes('PM')) {
		const [hh, ampm] = time.split(' ')
		if (!hh.includes(':')) {
			// If the time doesn't include minutes, add them
			return `${hh}:00 ${ampm}`
		}
		return time
	}
	let hhmm = time.split(':')
	// Check if input is a single number between 1 and 24
	if (hhmm.length < 2 && !isNaN(Number(time)) && Number(time) >= 1 && Number(time) <= 24) {
		hhmm = [time, '00']
	} else if (hhmm.length < 2) {
		return time
	}
	const [hh, mm] = hhmm
	const ampm = Number(hh) >= 12 ? 'PM' : 'AM'
	let hh12 = Number(hh) > 12 ? Number(hh) - 12 : hh

	if (padHours) {
		hh12 = hh12.toString().padStart(2, '0')
	}
	return `${hh12}:${mm} ${ampm}`
}

export const isProductionEnv = () => ENV === ENV_PRODUCTION

export function redirectIfChatIsNotEnabled(currentPracticeObject) {
	const navigate = useNavigate()
	useEffect(() => {
		if (!currentPracticeObject?.isChatEnabled) {
			navigate('/')
		}
	}, [currentPracticeObject, navigate])
}

export function redirectIfRemindersIsNotEnabled(currentPracticeObject) {
	const navigate = useNavigate()
	useEffect(() => {
		if (!currentPracticeObject) {
			return
		}
		if (!currentPracticeObject?.settings?.isRemindersEnabled) {
			navigate('/')
		}
	}, [currentPracticeObject, navigate])
}

export function redirectIfOneTimeEmailsIsNotEnabled(currentPracticeObject) {
	const navigate = useNavigate()
	useEffect(() => {
		if (!currentPracticeObject) {
			return
		}
		if (!currentPracticeObject?.settings?.isOneTimeEmailsEnabled) {
			navigate('/')
		}
	}, [currentPracticeObject, navigate])
}

/**
 * Formats the given amount as a string in '$0.00' format.
 *
 * @param {number} value - The value to be formatted.
 * @param {boolean} [isAmountInCents=true] - Optional. If true (default), the value is assumed to be in cents and will be divided by 100.
 * @param {boolean} [includeDollarSign=true] - Optional. If true (default), includes the dollar sign ('$') in the formatted string.
 * @returns {string} The formatted amount as a string.
 */
export const stripeAmountFormatter = (value, isAmountInCents = true, includeDollarSign = true) => {
	value = value ?? 0
	if (isAmountInCents) {
		value /= 100.0
	}
	const formattedAmount = numeral(value).format('0,0.00')
	return includeDollarSign ? `$${formattedAmount}` : formattedAmount
}

export const stripeTotalAmountFormatter = (data): any =>
	data.object === 'refund'
		? `(${stripeAmountFormatter(data.amount)})`
		: stripeAmountFormatter(data.amount)

// Utility for getting the charges and refunds out from the db
export const getPaymentInfo = (paymentIntent) => {
	// If we're "created", then we're from firestore.  If we're "createdAt", then we're graphQL.
	const isFirestore = !!paymentIntent.created
	const charge = isFirestore
		? paymentIntent?.charges?.data[0] || paymentIntent?.latestCharge
		: paymentIntent
	const refunds = charge?.refunds?.data
	const isRefundTransaction = paymentIntent.object === 'refund'

	return { charge, refunds, isRefundTransaction, isFirestore }
}

export const getSurchargeFee = (data): any => {
	const { refunds, isRefundTransaction, isFirestore } = getPaymentInfo(data)
	let fee
	if (!isFirestore) {
		return (data as PaymentHistory).surchargeFee
	}
	if (!isRefundTransaction) {
		fee = data?.metadata?.surchargeFee
	} else {
		refunds.forEach((obj) => {
			if (obj.id === data.id) {
				fee = obj?.metadata?.refundSurchargeFee
			}
		})
	}
	return fee || 0
}

export const getFormattedSurchargeFee = (data): any => {
	const { isRefundTransaction } = getPaymentInfo(data)
	const fee = getSurchargeFee(data)
	return fee >= 0 && !isRefundTransaction
		? stripeAmountFormatter(fee)
		: `(${stripeAmountFormatter(fee)})`
}

export const getPrintableSurchargeFee = (data): number => {
	const { isRefundTransaction } = getPaymentInfo(data)
	const fee = getSurchargeFee(data)
	const feeInDollars = convertCentsToDollars(fee)
	return feeInDollars >= 0 && !isRefundTransaction ? feeInDollars : -feeInDollars
}

export const getAmountWithoutSurchargeFee = (data): any => {
	const { refunds, isRefundTransaction, isFirestore } = getPaymentInfo(data)
	if (!isFirestore) {
		return (data as PaymentHistory).amountDue
	}
	if (isSurchargePayment(data)) {
		let amount
		if (!isRefundTransaction) {
			amount = data?.metadata?.amountWithoutSurcharge
		} else {
			refunds.forEach((obj) => {
				if (obj.id === data.id) {
					amount = obj?.metadata?.refundAmountWithoutSurcharge
				}
			})
		}
		return amount
	}
}

export const getRefundAmountWithoutSurchargeSum = (refunds: RefundCharge[]) =>
	refunds?.reduce((a: number, v: RefundCharge) => {
		const { isRefundTransaction } = getPaymentInfo(v)
		let refundedAmount = 0
		if (v.metadata?.refundAmountWithoutSurcharge && isRefundTransaction) {
			refundedAmount = Number(v.metadata?.refundAmountWithoutSurcharge)
		}
		return (a += refundedAmount)
	}, 0)

export const getRefundSurchargeFeeSum = (refunds: RefundCharge[]) =>
	refunds?.reduce((a: number, v: RefundCharge) => {
		const { isRefundTransaction } = getPaymentInfo(v)
		let refundedAmount = 0
		if (v.metadata?.refundSurchargeFee && isRefundTransaction) {
			refundedAmount = Number(v.metadata?.refundSurchargeFee)
		}
		return (a += refundedAmount)
	}, 0)

/**
 * Typescript gets a little confused with errors in Formik that could be a string or an element.  This narrows it
 * and prevents any bad values from appearing.
 * @param formik the Formik object
 * @param key the key of the formik validator to use
 * @returns the error in errors as a String, or null if there isn't one
 */
export function formikHelperText<Values>(
	formik: FormikState<Values>,
	key: keyof Values
): string | null {
	return formik.touched[key] && formik.errors[key] ? String(formik.errors[key]) : null
}

/**
 * Typescript gets a little confused with errors in Formik that could be a string or an element.  This narrows it
 * and prevents any bad values from appearing.
 * @param touched the FormikTouched object
 * @param errors the FormikErrors object
 * @param key the key of the formik validator to use
 * @returns the error in errors as a String, or null if there isn't one
 */
export function formikHelperText2<Values>(
	touched: FormikTouched<Values>,
	errors: FormikErrors<Values>,
	key: keyof Values
): string | null {
	return touched[key] && errors[key] ? String(errors[key]) : null
}

/**
 * gets the error message from the checkout desktop response
 * @param err the axios error object
 * @returns the error message
 * */
export const getCheckoutDesktopResponseErrorMessage = (
	err: AxiosError<{ error_message: string }>
): string => err?.response?.data?.error_message ?? ''

/**
 * Calculates the surcharge fee for a given amount based on the provided percentage.
 * The result is rounded to the nearest cent.
 *
 * @param {number} amount - The initial amount to calculate the surcharge fee on.
 * @param {number} surchargeFeePct - The percentage of the surcharge fee. For example, 0.03 represents 3%.
 * @returns {number} The calculated surcharge fee rounded to the nearest cent.
 */
export const calculateSurchargeFee = (amount: number, surchargeFeePct: number): number => {
	const decimalAmount = new Big(amount)
	const surchargeFeeInCents = decimalAmount.times(surchargeFeePct).times(100)
	const roundedSurchargeFee = surchargeFeeInCents.toFixed(0, 1) // 1 means ROUND_HALF_UP
	return parseInt(roundedSurchargeFee, 10)
}

/**
 * Calculates the amount without surcharge given the total amount with surcharge and the surcharge percentage.
 * The result is rounded to the nearest cent.
 *
 * @param {number} totalAmountWithSurcharge - The total amount including the surcharge.
 * @param {number} surchargeFeePct - The percentage of the surcharge fee. For example, 0.03 represents 3%.
 * @returns {object} An object containing:
 *   - surchargeFee: The surcharge fee in cents, rounded to the nearest cent.
 *   - amountWithoutSurcharge: The amount without the surcharge, in cents, rounded to the nearest cent.
 */
export const calculateAmountWithoutSurcharge = (
	amountWithSurchargeInCents: number,
	surchargeFeePct: number
) => {
	const decimalTotalAmount = new Big(amountWithSurchargeInCents)
	const decimalSurchargePct = new Big(surchargeFeePct)

	const surchargeMultiplier = new Big(1).plus(decimalSurchargePct)
	const amountWithoutSurcharge = decimalTotalAmount.div(surchargeMultiplier)
	const surchargeFee = decimalTotalAmount.minus(amountWithoutSurcharge)

	const roundedSurchargeFee = surchargeFee.toFixed(0, 1) // 1 means ROUND_HALF_UP
	const roundedAmountWithoutSurcharge = amountWithoutSurcharge.toFixed(0, 1) // 1 means ROUND_HALF_UP

	return {
		surchargeFee: parseInt(roundedSurchargeFee, 10),
		amountWithoutSurcharge: parseInt(roundedAmountWithoutSurcharge, 10)
	}
}

/**
 * Converts an amount from cents to dollars.
 * @param {number} cents - The value in cents to be converted.
 * @returns {number} The value in dollars.
 */
export const convertCentsToDollars = (cents: number) => Math.round(cents) / 100

/**
 * Converts an amount from dollars to cents.
 * @param {number} dollars - The value in dollars to be converted.
 * @returns {number} The value in cents.
 */
export const convertDollarsToCents = (dollars: number): number => {
	if (isNaN(dollars)) return dollars
	const cents = dollars * 100
	return cents
}

/**
 * Display a loading toast with a custom message.
 * @param {string} message - The message to display in the loading toast.
 * @returns {string} - The ID of the displayed toast.
 */
export const displayLoadingToast = (message: string) =>
	toast.loading(message, {
		duration: 10000
	})

/**
 * Converts a duration in minutes to a formatted string in hours and minutes.
 *
 * If the duration is less than 60 minutes, it returns the duration in minutes.
 * For durations of 60 minutes or more, it returns a combination of hours and minutes.
 * For example, 75 minutes is converted to "1 hr 15 min".
 *
 * @param {number} minutes - The duration in minutes to be converted.
 * @returns {string} A string representing the duration in hours and minutes.
 *
 * @example
 * // returns "30 min"
 * formatDurationInMinutesToString(30);
 *
 * @example
 * // returns "1 hr 15 min"
 * formatDurationInMinutesToString(75);
 */
export const formatDurationInMinutesToString = (minutes: number): string => {
	// Check if the number of minutes is less than 60
	if (minutes < 60) {
		return `${minutes} min`
	}

	// Calculate hours and remaining minutes
	const hours = Math.floor(minutes / 60)
	const remainingMinutes = minutes % 60

	// Format the string based on whether hours and minutes are present
	let formattedDuration = ''
	if (hours > 0) {
		formattedDuration += hours + (hours === 1 ? ' hr' : ' hrs')
	}
	if (remainingMinutes > 0) {
		formattedDuration += `${(hours > 0 ? ' ' : '') + remainingMinutes} min`
	}

	return formattedDuration
}

export const getStateList = (country) => (country === COUNTRY_CA ? CA_STATES : US_STATES)

// removes trailing / from a url
export const normalizeURL = (url) => (url.endsWith('/') ? url.slice(0, -1) : url)
