import {
	shouldShowCheckPayments,
	shouldShowECheckReportingPage,
	shouldShowInstallmentPlans,
	shouldShowStoredCards,
	shouldShowPaymentHistoryV2 as practiceShouldShowPaymentHistoryV2,
	shouldShowCustomPayoutsV2,
	shouldShowPaymentRequestsPage
} from '../util/practice'
import {
	shouldHideAccountsReceivable,
	shouldShowCheckPayments as consolidatorShouldShowCheckPayments,
	shouldShowPaymentHistoryV2 as consolidatorShouldShowPaymentHistoryV2,
	shouldShowRefundRequest,
	shouldHideReconciliation,
	shouldHidePayoutsPage,
	shouldShowMonthlyStatementsPage,
	shouldAllowSavePaymentMethod as shouldShowStoredCardsPage
} from '../util/consolidator'

export const getReportingSectionProps = (
	practice: IPractice,
	consolidator: IConsolidator | undefined
): ReportingSectionProps =>
	({
		showCheckPayments: shouldShowCheckPayments(
			practice,
			consolidatorShouldShowCheckPayments(consolidator)
		),
		showInstallmentPlans: shouldShowInstallmentPlans(practice),
		showSavedCards: shouldShowStoredCards(practice) || shouldShowStoredCardsPage(consolidator),
		showECheckReportingPage: shouldShowECheckReportingPage(practice),
		hideAccountsReceivable:
			shouldHideAccountsReceivable(consolidator) || shouldShowPaymentRequestsPage(practice),
		showCustomPayoutsV2: shouldShowCustomPayoutsV2(practice),
		showPaymentHistoryV2:
			consolidatorShouldShowPaymentHistoryV2(consolidator) ||
			practiceShouldShowPaymentHistoryV2(practice),
		showRefundRequests: shouldShowRefundRequest(consolidator),
		hideReconciliation: shouldHideReconciliation(consolidator),
		hidePayoutsPage: shouldHidePayoutsPage(consolidator),
		showMonthlyStatements: shouldShowMonthlyStatementsPage(consolidator)
	}) as ReportingSectionProps
