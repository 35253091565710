import { useState } from 'react'
import { Box, Tooltip, Alert, AlertTitle, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Info } from 'icons/Info'
import { useBannerStyles } from './BannerStyles'
import BannerButton from './BannerButton'
import { BannerVariant, BannerProps } from './banner.d'

export const Banner = ({
	title,
	subtitle,
	tooltip,
	href,
	buttonTitle,
	onButtonClick,
	disabled = false,
	showButton = true,
	variant = BannerVariant.ERROR
}: BannerProps) => {
	const { classes } = useBannerStyles()
	const [isOpened, setIsOpened] = useState(true)

	if (!isOpened) {
		return null
	}

	return (
		<Box className={classes.container}>
			<Tooltip title={tooltip}>
				<Alert
					severity="error"
					className={classes[variant]}
					icon={
						<Info
							style={{
								alignSelf: 'center',
								color: '#1D1D30',
								fontSize: '2rem'
							}}
						/>
					}
					action={
						<Box className={classes.actionBox}>
							{showButton && (
								<BannerButton {...{ href, buttonTitle, onButtonClick, variant, disabled }} />
							)}
							<IconButton className={classes.closeButton} onClick={() => setIsOpened(false)}>
								<CloseIcon fontSize="small" />
							</IconButton>
						</Box>
					}
				>
					<AlertTitle className={classes.title}>{title}</AlertTitle>
					{subtitle}
				</Alert>
			</Tooltip>
		</Box>
	)
}
