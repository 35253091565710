import { DateTime } from 'luxon'
import { eachDayOfInterval, endOfDay, format, startOfDay, parseISO } from 'date-fns'
import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz'

// This method will get the diff in days between two dates
export const getDiffDays = (firstDate, secondDate) => {
	const diffTime = Math.abs(firstDate - secondDate)
	return Math.ceil(diffTime / (1000 * 60 * 60 * 24))
}

export const getDiffInHours = (startDate: Date, endDate: Date = new Date()) => {
	const diffInMilliseconds = Math.abs(Number(endDate) - Number(startDate))
	const hours = diffInMilliseconds / (1000 * 60 * 60)
	return hours
}

export const validateStartDate = (startDate, endDate) => {
	if (!startDate) {
		return { isValid: false, errorMessage: 'Please select a Start Date' }
	}
	if (new Date(startDate) > new Date()) {
		return { isValid: false, errorMessage: 'Start date cannot be in the future' }
	}
	if (startDate && !endDate) {
		return { isValid: true }
	}
	if (startDate > endDate) {
		return { isValid: false, errorMessage: 'Start Date cannot be greater than End Date' }
	}
	return { isValid: true }
}

export const validateEndDate = (startDate, endDate) => {
	if (!endDate) {
		return { isValid: false, errorMessage: 'Please select an End Date' }
	}
	if (new Date(endDate) > DateTime.now().endOf('day').toUTC()) {
		return { isValid: false, errorMessage: 'End date cannot be in the future' }
	}
	if (endDate && !startDate) {
		return { isValid: true }
	}
	if (endDate < startDate) {
		return { isValid: false, errorMessage: 'End Date cannot be smaller than Start Date' }
	}
	return { isValid: true }
}

export const isTodaysDate = (date, dateFormat = 'yyyy-MM-dd') => {
	const today = DateTime.local()
	const selectedDate = DateTime.fromFormat(date, dateFormat)
	return today.hasSame(selectedDate, 'day')
}

export const getInstallmentStartDateFormat = (date) => {
	const installmentStartDateFormat = "yyyy-MM-dd'T'HH:mm:ss.SSSZZ"
	// if selected date is current date
	if (isTodaysDate(date)) {
		const today = DateTime.local()
		const timestamp = today.set({ millisecond: 0 }).toFormat(installmentStartDateFormat)
		return timestamp
	}
	// if future date
	const futureDate = DateTime.fromFormat(date, 'yyyy-MM-dd')
	const futureTimestamp = futureDate
		.set({ hour: 8, minute: 0, second: 0 })
		.toFormat(installmentStartDateFormat)
	return futureTimestamp
}

export const isPastDate = (date, dateFormat = 'yyyy-MM-dd') => {
	const today = DateTime.local()
	const selectedDate = DateTime.fromFormat(date, dateFormat)
	return selectedDate.startOf('day') < today.startOf('day')
}

export const getStartOfTheDay = (date, options = { useUTC: false }) => {
	const localDate = utcToZonedTime(date, Intl.DateTimeFormat().resolvedOptions().timeZone)
	return startOfDay(options.useUTC ? date : localDate)
}

export const getEndOfTheDay = (date, options = { useUTC: false }) => {
	const localDate = utcToZonedTime(date, Intl.DateTimeFormat().resolvedOptions().timeZone)
	return endOfDay(options.useUTC ? date : localDate)
}

export const getISOEquivalentOfDate = (date) => {
	return format(date, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
}

export const resolveStartAndEndDates = (
	startDate: string,
	endDate: string,
	options = {
		useUTC: false
	}
): DateRange => {
	if (!startDate || !endDate) return {}

	const startOfTheDay = getStartOfTheDay(parseISO(startDate), options)
	const endOfTheDay = getEndOfTheDay(parseISO(endDate), options)

	return {
		startDate: getISOEquivalentOfDate(startOfTheDay),
		endDate: getISOEquivalentOfDate(endOfTheDay)
	}
}

export const timeZoneOptions: TimeZoneOption[] = [
	{ id: 'America/New_York', name: 'Eastern Time' },
	{ id: 'America/Chicago', name: 'Central Time' },
	{ id: 'America/Denver', name: 'Mountain Time' },
	{ id: 'America/Phoenix', name: 'Arizona Time' },
	{ id: 'America/Los_Angeles', name: 'Pacific Time' },
	{ id: 'America/Anchorage', name: 'Alaska Time' },
	{ id: 'Pacific/Honolulu', name: 'Hawaii Time' },
	{ id: 'America/Halifax', name: 'Atlantic Time' },
	{ id: 'America/Regina', name: 'Saskatchewan Time' },
	{ id: 'America/St_Johns', name: 'Newfoundland Time' }
]

export const getTimeZoneName = (tzName) =>
	timeZoneOptions.find((opt) => opt.id === tzName)?.name ?? tzName

export const formatDate = (date: string, formatStr: string = 'MMM dd, yyyy | p') => {
	if (!date) {
		return ''
	}
	return format(new Date(date), formatStr)
}

export const formatTimeInTimeZone = (
	date: string,
	timzone: string = 'America/New_York',
	formatStr = 'p zzz'
) => {
	if (!date) {
		return ''
	}
	return formatInTimeZone(date, timzone, formatStr)
}

export const getDatesBetweenRange = (startDate, endDate) => {
	if (startDate && endDate) {
		const startDateNormalized = startOfDay(startDate)
		const endDateNormalized = endOfDay(endDate)
		return eachDayOfInterval({ start: startDateNormalized, end: endDateNormalized }).map((date) =>
			format(date, 'MM/dd/yyyy')
		)
	}
	return []
}

export const mergeFollowedDates = (dateArray) => {
	if (!dateArray || dateArray.length === 0) {
		return []
	}
	const sortedDates = dateArray.map((dateString) => new Date(dateString)).sort((a, b) => a - b)
	const mergedRanges: any = []

	let startDate = sortedDates[0]
	let endDate = sortedDates[0]

	for (let i = 1; i < sortedDates.length; i++) {
		const currentDate = sortedDates[i]
		const differenceInDays = (currentDate - endDate) / (1000 * 60 * 60 * 24)

		if (differenceInDays === 1) {
			// Dates are consecutive, update the endDate
			endDate = currentDate
		} else {
			// Dates are not consecutive, add the previous range to the mergedRanges array
			if (startDate.getTime() === endDate.getTime()) {
				// If startDate and endDate are the same, add a single date to the mergedRanges
				mergedRanges.push({
					label: format(startDate, 'MM/dd/yyyy'),
					dates: [format(startDate, 'MM/dd/yyyy')]
				})
			} else {
				mergedRanges.push({
					label: `${format(startDate, 'MM/dd/yyyy')} - ${format(endDate, 'MM/dd/yyyy')}`,
					dates: getDatesBetweenRange(startDate, endDate)
				})
			}

			// Start a new range with the current date as both start and end date
			startDate = currentDate
			endDate = currentDate
		}
	}

	// Add the last range to the mergedRanges array
	if (startDate.getTime() === endDate.getTime()) {
		// If startDate and endDate are the same, add a single date to the mergedRanges
		mergedRanges.push({
			label: format(startDate, 'MM/dd/yyyy'),
			dates: [format(startDate, 'MM/dd/yyyy')]
		})
	} else {
		mergedRanges.push({
			label: `${format(startDate, 'MM/dd/yyyy')} - ${format(endDate, 'MM/dd/yyyy')}`,
			dates: getDatesBetweenRange(startDate, endDate)
		})
	}

	return mergedRanges
}

export const isValidDate = (dateString) => {
	const date = new Date(dateString)
	return !isNaN(date.getTime())
}

export const getDateLabel = (dateString: string, formatStr: string = 'MM/dd/yyyy') => {
	if (dateString === undefined || dateString === null || !isValidDate(dateString)) {
		return 'N/A'
	}

	const date = new Date(dateString)
	const utcDate = utcToZonedTime(date, 'UTC')
	return format(utcDate, formatStr)
}

/**
 * Converts a given UNIX timestamp into a human-readable date format.
 *
 * @param {number} timestamp - The UNIX timestamp in seconds.
 * @param {string} [formatStr='MM/dd/yyyy'] - The desired format of the resulting date. Defaults to 'MM/dd/yyyy'.
 * @returns {string} The formatted date as a string. Returns an empty string if no timestamp is provided or if the timestamp is 0.
 */
export const formatUnixDate = (timestamp: number, formatStr: string = 'MM/dd/yyyy'): string => {
	if (!timestamp) return ''
	return format(new Date(Math.round(timestamp * 1000)), formatStr)
}

/**
 * Converts a given day time into a date format.
 *
 * @param {string} time - The day time in hh:mm (a|p)m format.
 */
export const dayTimeToDate = (time: string) => {
	const parts = time.split(' ')
	const timeParts = parts[0].split(':')
	let hours = parseInt(timeParts[0], 10)
	const minutes = parseInt(timeParts[1], 10)

	if (parts[1].toUpperCase() === 'PM' && hours !== 12) {
		hours += 12
	}

	const date = new Date()
	date.setHours(hours)
	date.setMinutes(minutes)
	date.setSeconds(0)
	date.setMilliseconds(0)
	return date
}

// returns a given data in format YYYY-MM-DD
export const removeTimestampFromDate = (date: string) => date.split('T')[0]

export const getTimezoneOffset = (value) => value.getTimezoneOffset() * 60000

export const makeLocalAppearUTC = (value) => {
	const dateTime = new Date(value)
	const utcFromLocal = new Date(dateTime.getTime() + getTimezoneOffset(dateTime))
	return utcFromLocal
}

export const localToUTC = (dateTime) => {
	const utcFromLocal = new Date(dateTime.getTime() - getTimezoneOffset(dateTime))
	return utcFromLocal
}

/**
 * Converts a time string from 24-hour (military) format to 12-hour format with AM/PM notation.
 *
 * @param {string} time - The time string in 24-hour format (e.g., "14:30", "08:15").
 * @returns {string} - The time string in 12-hour format with AM/PM notation (e.g., "02:30 PM", "08:15 AM").
 * */
export const convert24HourTo12HourFormat = (time: string) => {
	const timeParts = time.split(':')
	let hours = parseInt(timeParts[0], 10)
	const minutes = parseInt(timeParts[1], 10)

	const ampm = hours >= 12 ? 'PM' : 'AM'
	hours = hours % 12 || 12

	const formattedHours = hours.toString().padStart(2, '0')
	const formattedMinutes = minutes.toString().padStart(2, '0')

	return `${formattedHours}:${formattedMinutes} ${ampm}`
}

export const getUserTimeZone = () => {
	const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
	return timeZone
}

export const convertUTCToTimeZone = (utcTimeStr, targetTimeZone) => {
	// Parse the UTC time string into a Luxon DateTime object
	let dateTime = DateTime.fromFormat(utcTimeStr, 'h:mm a', { zone: 'utc' })

	// Format the Luxon DateTime object to the desired time zone
	let localTime = dateTime.setZone(targetTimeZone).toLocaleString(DateTime.TIME_SIMPLE)

	return localTime
}

export const setTimeToDate = (dateStr, timeStr) => {
	// Parse the date string into a Luxon DateTime object
	let dateTime = DateTime.fromISO(dateStr, { zone: 'utc' })

	// Extract hours and minutes from the time string
	let [hoursStr, minutesStr] = timeStr.split(':')
	let hours =
		parseInt(hoursStr) +
		(timeStr.toLowerCase().includes('pm') && parseInt(hoursStr) !== 12 ? 12 : 0)

	// Set the hours and minutes in the Luxon DateTime object
	dateTime = dateTime.set({ hour: hours, minute: parseInt(minutesStr) })

	// Convert Luxon DateTime object to Date object in UTC
	let zonedDate = utcToZonedTime(dateTime.toJSDate(), 'UTC')

	// Return the zoned date as an ISO string
	return zonedDate.toISOString()
}
