export enum THEMES {
	LIGHT = 'light',
	DARK = 'dark',
	NATURE = 'nature'
}

export const HEADER_HEIGHT = '64px'
export const FOOTER_HEIGHT = '50px'
export const DRAWER_WIDTH = '280px'
export const CONSOLIDATOR_ID_VETCORE = '8'
export const CONSOLIDATOR_ID_MVP = '3'
export const CONSOLIDATOR_ID_THRIVE = '11'
export const CONSOLIDATOR_ID_RAREBREED = '65'
export const CONSOLIDATOR_ID_BRM = '5'
export const PARTNER_ID_BRAVA = 'brava'

export const BRAVA_NAME_REGEX_PATTERNS: string[] = [
	'\\[BR\\]$',
	'\\[BRAVA\\]$',
	'^Brava$',
	'^Brava CA$',
	'^Blue Rabbit Ventures Inc$'
]

export const DISABLE_PAYOUT_REPORTING_SUMMARY_IDS: Array<string | undefined> = [
	CONSOLIDATOR_ID_BRM,
	CONSOLIDATOR_ID_THRIVE,
	CONSOLIDATOR_ID_VETCORE
]

export const PAYMENT_STATUS = {
	SUCCEEDED: 'succeeded',
	CANCELED: 'canceled',
	REQUIRES_PAYMENT_METHOD: 'requires_payment_method'
}

export const PRACTICE_ROLE_SCRATCH_ADMIN = 'scratchAdmin'
export const PRACTICE_ROLE_ADMIN = 'admin'
export const PRACTICE_ROLE_ACCOUNTING_MANAGER = 'accountingManager'
export const PRACTICE_ROLE_HOSPITAL_MANAGER = 'hospitalManager'
export const PRACTICE_ROLE_REGIONAL_MANAGER = 'regionalManager'
export const PRACTICE_ROLE_STAFF = 'staff'
export const FINANCING_ONLY_PRACTICE_ROLE = PRACTICE_ROLE_HOSPITAL_MANAGER

export const ENV_PRODUCTION = 'production'

export const US_STATES = [
	'AL',
	'AK',
	'AZ',
	'AR',
	'CA',
	'CO',
	'CT',
	'DC',
	'DE',
	'FL',
	'GA',
	'HI',
	'ID',
	'IL',
	'IN',
	'IA',
	'KS',
	'KY',
	'LA',
	'ME',
	'MD',
	'MA',
	'MI',
	'MN',
	'MS',
	'MO',
	'MT',
	'NE',
	'NV',
	'NH',
	'NJ',
	'NM',
	'NY',
	'NC',
	'ND',
	'OH',
	'OK',
	'OR',
	'PA',
	'RI',
	'SC',
	'SD',
	'TN',
	'TX',
	'UT',
	'VT',
	'VA',
	'WA',
	'WV',
	'WI',
	'WY'
]

export const CA_STATES = ['AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'ON', 'PE', 'QC', 'SK']

export const CHARGE_STATUS_TEXT_UNCAPTURED = 'Uncaptured'
export const CHARGE_STATUS_TEXT_FAILED = 'Failed'
export const CHARGE_STATUS_TEXT_FAILED_E_CHECK = 'Failed eCheck'
export const CHARGE_STATUS_TEXT_RETURNED_E_CHECK = 'Reversed eCheck'
export const CHARGE_STATUS_DISPUTED = 'Disputed'
export const STATUS_SUCCESS = 'success'
export const STATUS_LOADING = 'loading'
export const RETURNED_E_CHECK = 'returnedECheck'

export const STATE_SUCCEEDED = 'succeeded'
export const STATE_FAILED = 'failed'
export const STATE_CANCELED = 'canceled'

export const AVIMARK = 'AVImark'
export const CORNERSTONE = 'Cornerstone'
export const DENTICON = 'Denticon'
export const DENTRIX = 'Dentrix'
export const EZYVET = 'ezyVet'
export const VETSPIRE = 'vetspire'

export const PIMS_OPTIONS = [AVIMARK, CORNERSTONE, DENTICON, DENTRIX, VETSPIRE, EZYVET, 'Other']
export const PIMS_OPTIONS_FOR_VETCOR = [
	AVIMARK,
	CORNERSTONE,
	'DVMax',
	'EVetPractice',
	EZYVET,
	'IDEXX Neo',
	'Infinity',
	'Intravet',
	VETSPIRE,
	'Vetter',
	'VIA',
	'VTech',
	'Other'
]

export const COUNTRY_US = 'US'
export const COUNTRY_CA = 'CA'

export const CURRENCY_US = 'usd'
export const CURRENCY_CA = 'cad'

export const CONNECTION_STATUS_CONNECTED = 'connected'
export const CONNECTION_STATUS_CONNECTING = 'connecting'

export const REMINDER_TYPES = {
	REMINDER: 'reminder',
	CONFIRMATION: 'confirmation'
}

export const COMMUNICATION_REPORTING_TYPES = {
	METRICS: 'metrics',
	HISTORY: 'history'
}

export const READER_ADMIN_PIN = '07139'

export const checkScratchPaymentMethods = [
	'lockbox_check',
	'check_payment',
	'Check Payment',
	'us_bank_account',
	'paper_check',
	'ach_debit'
]

export const bankPaymentMethods = ['us_bank_account', 'acss_debit']

export const AG_GRID_EXPORT_TYPES = {
	CSV: 'csv',
	EXCEL: 'excel',
	PRINTER_FRIENDLY_CSV: 'printer_friendly_csv'
}

export const ECHECK_PAYMENT_METHOD = 'check_payment'

export const ECHECK_PAYMENT_PAGES = {
	MAIN: 'main',
	CHECK: 'check',
	LOCKBOX: 'lockbox'
}

export const enum ACTION_ITEM_TYPES {
	FAILED_CHECK = 'FAILED_CHECK',
	AR_PAYMENT_RECEIVED = 'AR_PAYMENT_RECEIVED',
	FAILED_LOCKBOX = 'FAILED_LOCKBOX',
	MOBILE_PAYMENT = 'MOBILE_PAYMENT'
}

export const COMMS_SIDEBAR_WIDTH = 400

export const enum RequestLevel {
	PRACTICE = 'PRACTICE',
	MANAGER = 'MANAGER',
	ADMIN = 'ADMIN',
	SCRATCHADMIN = 'SCRATCHADMIN'
}

export const enum TwilioFlowName {
	TEXT_APPLICATION = 'textApplication'
}

export const SHIPPING_METHODS = [
	{ type: 'standard', label: 'Standard (5-7 Business Days)' },
	{ type: 'express', label: 'Express (3-4 Business Days)' },
	{ type: 'priority', label: 'Priority (1-2 Business Days)' }
]

export const ROUTES = {
	accountUri: 'financing/account',
	bankAccountUri: 'financing/account/bank-account',
	plansUri: 'financing/plans'
}

export const MANUAL_APPOINTMENT_INSERT_FLAG = 'isManualAppointmentInsertEnabled'
export const SMART_APPOINTMENT_REQUESTS_FLAG = 'isSmartAppointmentRequestsEnabled'
export const DOCTOR_CUSTOMIZATIONS_FLAG = 'isDoctorCustomizationsEnabled'
export const DIRECT_BOOKING_FLAG = 'isDirectBookingEnabled'
export const DIRECT_BOOKING_STORED_CARD_FLAG = 'isDirectBookingStoredCardEnabled'
export const DIRECT_BOOKING_DEPOSIT_FLAG = 'isDirectBookingDepositEnabled'
export const REPUTATION_EMAIL_FLAG = 'isReputationEmailEnabled'
export const COMMS_SETTINGS_FLAG = 'isCommunicationSettingsEnabled'
export const REMINDERS_ENABLED_FLAG = 'isRemindersEnabled'
export const APPOINTMENT_REQUESTS_ENABLED_FLAG = 'isAppointmentRequestsEnabled'
export const POSTCARD_PAGE_ENABLED_FLAG = 'isPostcardsPageEnabled'

export const SCRATCH_PAY_PUBLIC_URL_STG = 'https://client.staging.scratchpay.com'
export const SCRATCH_PAY_PUBLIC_URL_PRD = 'https://client.scratchpay.com'

export const FINANCING_STATUS = {
	ACTIVE: 'active',
	HIDDEN: 'hidden'
}

export const PLANS_FILTER_ON_OPTIONS = {
	APPLIED_DATE: 'Applied',
	CONFIRMED_DATE: 'Confirmed'
}

export const PLANS_QUICK_FILTERS = {
	ALL: 'All',
	LAST_7_DAYS: 'Last 7 days',
	NEW: 'New',
	FINALIZED: 'Finalized',
	REOPEN: 'Reopen',
	APPROVED: 'Approved',
	REJECTED: 'Rejected',
	EXPIRED: 'Expired'
}
