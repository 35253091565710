import { shouldShowStripeFutureRequirements as shouldShowStripeFutureRequirementsBasedOnFlag } from 'util/practice'
import { isManagerOrAdmin } from 'util/unified'

export const canViewStripeRequirementsBanner = (currentPractice: IPractice, currentUser: User) => {
	// Don't show stripe requirements banner when it is not loaded
	if (!currentPractice?.id || !currentUser) {
		return false
	}

	// Only practices without a consolidator (independent practice) have permission
	if (currentPractice?.consolidatorId) {
		return false
	}

	return true
}

// Only Hospital Managers or Scratch Admins have permission
export const hasPermissionForUpdatingStripeRequirements = (context, currentPractice) => {
	return isManagerOrAdmin(context, currentPractice)
}

const hasPendingImmediateRequirements = (currentPracticeObject: IPractice) =>
	currentPracticeObject.additionalStripeRequiredInfo?.length ?? false
const hasPendingFutureRequirements = (currentPracticeObject: IPractice) =>
	currentPracticeObject.additionalStripeFutureRequirements?.currently_due?.length ||
	currentPracticeObject.additionalStripeFutureRequirements?.eventually_due?.length ||
	false

export const shouldShowPracticeRequirements = (
	currentPracticeObject: IPractice,
	currentUser: User
) => {
	// Dont show practice requirements banner when user hasn't permission to view
	if (!canViewStripeRequirementsBanner(currentPracticeObject, currentUser)) {
		return false
	}

	return hasPendingImmediateRequirements(currentPracticeObject)
}

export const shouldShowPracticeFutureRequirements = (
	currentPracticeObject: IPractice,
	currentUser: User
) => {
	// Dont show future requirements banner when practice flag is off
	if (!shouldShowStripeFutureRequirementsBasedOnFlag(currentPracticeObject)) {
		return false
	}

	// Dont show future requirements banner when user hasn't permission to view
	if (!canViewStripeRequirementsBanner(currentPracticeObject, currentUser)) {
		return false
	}

	// Dont show future requirements banner when immediate requirements exists
	if (hasPendingImmediateRequirements(currentPracticeObject)) {
		return false
	}

	return hasPendingFutureRequirements(currentPracticeObject)
}

export const paymentRequestsEnabled = (currentPracticeObject, currentUserConsolidator) => {
	const practiceEnabled = currentPracticeObject?.settings?.isPaymentRequestEnabled
	const consolidatorEnabled = currentUserConsolidator?.settings?.isPaymentRequestEnabled
	if (typeof practiceEnabled !== 'undefined') {
		return practiceEnabled
	}
	if (typeof consolidatorEnabled !== 'undefined') {
		return consolidatorEnabled
	}
	return true
}
